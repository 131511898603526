/* eslint-disable @next/next/no-img-element */
import {Text, useIsMobileOrTablet} from '@prescriberpoint/ui';
import {FC} from 'react';

interface IChatBubbleProps {
  children: React.ReactNode;
  isMobile?: boolean;
}

const ChatBubble: FC<IChatBubbleProps> = ({children, isMobile = false}) => (
  <div className="whitespace-nowrap rounded-full bg-neutral-darker/8 py-2 md:px-6">
    <Text as={'body-md'} size={isMobile ? 'sm' : 'lg'}>
      {children}
    </Text>
  </div>
);

const questions = [
  'What are the indications for Xeljanz',
  'How do I store Emgality',
  'Is Dupixent FDA approved for asthma',
  'Vyepti self-administered',
  'What is Depakote XR used for',
  'What is the dosage for Mounjaro',
];

export interface IAuthBackgroundProps {}

const AuthBackground: FC<IAuthBackgroundProps> = () => {
  const isMobileOrTablet = useIsMobileOrTablet();

  return (
    <div className="relative flex flex-1 flex-col items-center justify-center bg-[url('https://cdn.prescriberpoint.com/assets/images/blur-ai.png')] bg-cover p-8">
      <div className="flex flex-col items-center justify-center">
        <img
          width={112}
          height={72}
          alt="ai-logo"
          src="https://cdn.prescriberpoint.com/assets/images/prescriber-ai.png"
        />
        <div className="flex items-center">
          <Text
            as={isMobileOrTablet ? 'headline-xl' : 'display-lg'}
            weight="bold"
            className={{'text-[64px] leading-[140%]': !isMobileOrTablet}}>
            Prescriber
          </Text>
          <Text
            as={isMobileOrTablet ? 'headline-xl' : 'display-lg'}
            weight="light"
            className={{'text-[64px] leading-[140%]': !isMobileOrTablet}}>
            AI
          </Text>
        </div>
        <div className="text-center">
          <Text
            size={isMobileOrTablet ? 'md' : 'lg'}
            className="text-neutral-darker/72">
            Get referenced answers from FDA-approved labels for over{' '}
            <Text
              size={isMobileOrTablet ? 'md' : 'lg'}
              weight="bold"
              className="text-neutral-darker/72">
              35,000
            </Text>{' '}
            medications, fast.
          </Text>
        </div>
        <div className="mt-8 rounded-full bg-white px-5 py-5 text-center shadow-deep md:mt-16 md:w-[550px] md:py-[37px]">
          <Text
            as={isMobileOrTablet ? 'title-lg' : 'headline-lg'}
            weight="bold"
            className="bg-white text-neutral-secondary-alt">
            Hi. What can I help with?
          </Text>
        </div>
        <div className="flex flex-col items-center space-y-8 pt-8 md:pt-16">
          <Text
            as={isMobileOrTablet ? 'headline-sm' : 'headline-lg'}
            className="text-neutral-secondary-alt">
            Try searching...
          </Text>
          <div className="flex max-w-[800px] flex-wrap items-center justify-center gap-2 md:gap-4">
            {questions.map((question) => (
              <ChatBubble key={question} isMobile={isMobileOrTablet}>
                {question}
              </ChatBubble>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthBackground;
