import clsx from 'clsx';
import {FC} from 'react';

type ResponsiveWrapperShowType = 'desktop' | 'mobile' | 'mobileAndTablet';
export interface IResponsiveWrapperProps {
  show: ResponsiveWrapperShowType;
  children: React.ReactNode;
}

const ResponsiveWrapper: FC<IResponsiveWrapperProps> = ({show, children}) => (
  <div
    className={clsx({
      'hidden md:block': show === 'desktop',
      'block sm:hidden': show === 'mobile',
      'block md:hidden': show === 'mobileAndTablet',
    })}>
    {children}
  </div>
);

export default ResponsiveWrapper;
