'use client';
import dynamic from 'next/dynamic';
import {FC, ReactNode} from 'react';
import AuthBackground from '@/components/Auth/NewAuth/AuthBackground';
import ResponsiveWrapper from '@/components/ResponsiveWrapper';
import {useIsProductAi} from '@/hooks/useIsProductAi';
import Features from '@/modules/SignupPage/Features';

const AuthNavigation = dynamic(
  () => import('@/components/Navbar/AuthNavigation'),
  {
    ssr: false,
  },
);

interface AuthLayoutProps {
  children: ReactNode;
  hideFeatures?: boolean;
}

const AuthLayout: FC<AuthLayoutProps> = ({children, hideFeatures = false}) => {
  const isAi = useIsProductAi();
  return (
    <div className='flex min-h-screen flex-col'>
      <AuthNavigation />

      {isAi ? (
        <>
          <ResponsiveWrapper show='desktop'>
            <div className='flex flex-1 flex-row'>
              <AuthBackground />
              <div className='min-h-full w-full bg-neutral-lighter-alt'>
                {children}
              </div>
            </div>
          </ResponsiveWrapper>

          <ResponsiveWrapper show='mobileAndTablet'>
            <div className='flex flex-1 flex-col'>
              <div className='flex min-h-[50vh] w-full flex-col bg-neutral-lighter-alt'>
                {children}
              </div>
              <AuthBackground />
            </div>
          </ResponsiveWrapper>
        </>
      ) : (
        <div className='flex flex-1 flex-col md:flex-row'>
          <div className='flex min-h-[50vh] w-full flex-col bg-neutral-lighter-alt md:min-h-full md:w-[560px]'>
            {children}
          </div>
          <div className='flex flex-1 flex-col items-center justify-center bg-neutral-dark p-8'>
            {!hideFeatures ? <Features /> : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthLayout;
